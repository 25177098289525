* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  font-size: .9rem;
  background: url(./images/background.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
p {
  color: #000000;
  text-shadow: 0 0 2px #ffffff;
  margin-bottom: 1rem;
}
a {
  text-decoration: none;
}

.App {
  text-align: center;
}

.Family-logo {
  width: 600px;
  background-color: none;
}

.App-background {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}


.Companies-container {
  display: flex;
  flex-direction: row;
  height: 60vh;
}

.Companies {
  border-right: solid 1px rgba(0, 0, 0, 0.5);
  background: rgba(255, 255, 255, 0.9);
  align-content: center;
  width: 25%;
  height: 100%;
}

.Companies:hover {
  background: rgba(255, 255, 255, .95);
}


.margin {
  margin: 1rem;
}

.logo {
  width: 75%;
}
.Montgomery-properties-logo {
  width: 47%;
}

.Montgomery-properties {
  border-radius: 30px 0px 0px 0px;
}

.MIG {
  border-radius: 0px 30px 0px 0px;
}

.MIG-logo {
  margin: 2.3rem;  
}

.MB-logo {
  margin: 2.0rem;
}

.Americare-logo {
  margin: 1.7rem;
}

.Family-logo {
  align-self: flex-start;
}

@media (max-width: 1000px) {
  .Companies-container {
    height: 70vh;
  }
}



@media (max-width: 800px) {
  html {
    background-color: #F3F2F3;
    overflow-x: hidden !important;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
     box-sizing: border-box;
  }
  .Companies-container {
    flex-direction: column;
    height: 100%;
  }
  .Family-logo {
    align-self: center; 
  }
  .Family-logo-image {
    width: 70%;
  }
  .Companies {
    border: none;
    width: 90%;
    padding: 5%;
    border-radius: 0;
    align-self: center;
    margin-bottom: 5%;
  }
  .Montgomery-properties {
    border-top: none;
    margin-top: 5%;
  }
}


